import { LG, MD, Paragraph, SM } from '@zendeskgarden/react-typography'
import React, { useContext } from 'react'
import { dateToString, integerToDate, currentIntegerDate } from '../ExandrianDateUtils'
import { nonLegendaryItemsBySeed } from '../data/Items'
import styled from 'styled-components'
import { Anchor } from '@zendeskgarden/react-buttons'
import { NavExpandedContext } from './Root'
import { useParams, Navigate } from 'react-router-dom'

const StyledParagraph = styled(Paragraph)`
  background-color: ${props => props.theme.palette.grey[100]};
  display: inline-block;
  padding: ${props => props.theme.space.sm};
  margin: ${props => props.theme.space.sm};
`

export const Item = ({ item }) => {
  const beyondName = item.link ?? item.name.toLowerCase().replace(/ /g, '-')
  const toolsName = (item.toolsLink ?? item.name.toLowerCase().replace(/ /g, '%20')) + '_' + (item.source ?? 'dmg')
  const itemLink = `https://www.dndbeyond.com/magic-items/${beyondName}`
  const toolsItemLink = `https://5e.tools/items.html#${toolsName}`
  return (
    <StyledParagraph>
      <LG>{item.name}</LG>
      <SM><Anchor isExternal href={itemLink}>{'D&D Beyond'}</Anchor>{' | '}<Anchor isExternal href={toolsItemLink}>{'5etools'}</Anchor></SM>
      <MD>{item.description}</MD>
      <MD>{item.rarity}</MD>
      <MD>{item.price}</MD>
    </StyledParagraph>
  )
}

export const Shop = () => {
  const dayParam = useParams().day
  const [day, setDay] = React.useState(dayParam ?? currentIntegerDate)
  const setIsExpanded = useContext(NavExpandedContext)
  setIsExpanded(false)
  const date = integerToDate(day)
  const currentDate = dateToString(date.day, date.month, date.year)
  const items = nonLegendaryItemsBySeed(currentDate, 8, 7)
  const uniqueRandomItems = [...new Set(items)]

  if (dayParam === undefined) {
    return <Navigate to={`/shop/${day}`} />
  }

  return (
    <>
      <Paragraph>{currentDate}</Paragraph>

      {/* <Paragraph>
        <Button onClick={() => setDay(day - 1)}>
        Previous day
        </Button>
        <Button onClick={() => setDay(day + 1)}>
        Next day
        </Button>
        </Paragraph> */}
      <Paragraph>Welcome!</Paragraph>
      <Paragraph>Here are the items for sale today:</Paragraph>
      {uniqueRandomItems.map((item, index) => <Item key={index} item={item} />)}
    </>
  )
}
